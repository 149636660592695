// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
//
// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

import jquery from 'jquery'
window.jquery = jquery
window.jQuery = jquery
window.$ = jquery

import select2 from 'select2';
window.select2 = select2;
// import 'select2/dist/css/select2.css'
import 'select2/dist/js/i18n/zh-TW'

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
// require("jquery")
require("@nathanvda/cocoon")
//= require jquery
//= require jquery_ujs
//= require jquery-ui/widgets/sortable
//= require rails_sortable

